import request from '@/utils/request'

const adminApi = {
    Login: '/adminApi/admin/login',
    ChangePassword:'/adminApi/admin/changePassword',
    GetAdminInfo:'/adminApi/admin/getAdminInfo',
    UpdateAdminInfo:'/adminApi/admin/updateAdminInfo',
    GetAdminList:'/adminApi/admin/getAdminList',
    AddAdmin:'/adminApi/admin/addAdmin',
    EditAdmin:'/adminApi/admin/editAdmin',
    GetAdminAuthRule:'/adminApi/admin/getAdminAuthRule',
    AddAuthRule:'/adminApi/admin/addAuthRule',
    EditAuthRule:'/adminApi/admin/editAuthRule',
    DeleteAuthRule:'/adminApi/admin/deleteAuthRule',
    GetAuthGroupList:'/adminApi/admin/getAuthGroupList',
    AddAuthGroup:'/adminApi/admin/addAuthGroup',
    EditAuthGroup:'/adminApi/admin/editAuthGroup',
    DeleteAuthGroup:'/adminApi/admin/deleteAuthGroup',
    AssignPermissions:'/adminApi/admin/assignPermissions',
    Logout:'/adminApi/admin/logout'
}

//登录
export function login (parameter) {
    return request({
        url: adminApi.Login,
        method: 'post',
        data: parameter
    })
}

//修改密码
export function changePassword(parameter){
    return request({
        url: adminApi.ChangePassword,
        method: 'post',
        data: parameter
    })
}

//获取管理员信息
export function getAdminInfo(){
    return request({
        url: adminApi.GetAdminInfo,
        method: 'get',
    })
}

//更新管理员信息
export function updateAdminInfo(parameter){
    return request({
        url: adminApi.UpdateAdminInfo,
        method: 'post',
        data: parameter
    })
}

// 获取管理列表
export function getAdminList(parameter){
    return request({
        url: adminApi.GetAdminList,
        method: 'get',
        params: parameter
    })
}

//添加管理员
export function addAdmin(parameter){
    return request({
        url: adminApi.AddAdmin,
        method: 'post',
        data: parameter
    })
}

//编辑管理员
export function editAdmin(parameter){
    return request({
        url: adminApi.EditAdmin,
        method: 'post',
        data: parameter
    })
}

//获取菜单
export function getAdminAuthRule(parameter){
    return request({
        url: adminApi.GetAdminAuthRule,
        method: 'get',
        params: parameter
    })
}

//添加菜单
export function addAuthRule(parameter){
    return request({
        url: adminApi.AddAuthRule,
        method: 'post',
        data: parameter
    })
}

//编辑菜单
export function editAuthRule(parameter){
    return request({
        url: adminApi.EditAuthRule,
        method: 'post',
        data: parameter
    })
}

//删除菜单
export function deleteAuthRule(parameter){
    return request({
        url: adminApi.DeleteAuthRule,
        method: 'post',
        data: parameter
    })
}

//获取角色组
export function getAuthGroupList(parameter){
    return request({
        url: adminApi.GetAuthGroupList,
        method: 'get',
        params: parameter
    })
}

//添加角色组
export function addAuthGroup(parameter){
    return request({
        url: adminApi.AddAuthGroup,
        method: 'post',
        data: parameter
    })
}

//编辑角色组
export function editAuthGroup(parameter){
    return request({
        url: adminApi.EditAuthGroup,
        method: 'post',
        data: parameter
    })
}

//删除角色组
export function deleteAuthGroup(parameter){
    return request({
        url: adminApi.DeleteAuthGroup,
        method: 'post',
        data: parameter
    })
}

// 分配权限
export function assignPermissions(parameter){
    return request({
        url: adminApi.AssignPermissions,
        method: 'post',
        data: parameter
    })
}

//退出登录
export function logout(){
    return request({
        url: adminApi.Logout,
        method: 'post',
    })
}
  
