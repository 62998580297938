import { defineStore } from 'pinia';
import storage from 'store'
import { login, getAdminInfo,updateAdminInfo, logout} from '@/api/admin'
import { updateSystemInfo } from '@/api/system'
import pinia from '@/stores/store'
import useAsyncRouter from '@/stores/async-router';
const asyncRouterStore = useAsyncRouter(pinia);

const useAdmin = defineStore({
    id:'admin',
    state(){  // 存放的就是模块的变量
        return{
            adminInfo:{},
            permissions:{},
            systemInfo:{}
        }
    },

    getters:{ // 相当于vue里面的计算属性，可以缓存数据
        
    },

    actions:{ // 可以通过actions 方法，改变 state 里面的值。
        //登录
        Login(values){
            return new Promise((resolve, reject) => {
                login(values).then(response => {
                    const result = response.result;
                    storage.set('token', result);
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            })
        },

        //获取管理员信息
        GetAdminInfo(){
            return new Promise((resolve, reject) => {
                getAdminInfo().then(response => {
                    const result = response.result;
                    this.adminInfo = result;
                    this.permissions = result.permissions;
                    this.systemInfo = result.systemInfo;
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            })
        },

        //更新管理信息
        UpdateAdminInfo(values){
            return new Promise((resolve, reject) => {
                updateAdminInfo(values).then(response => {
                    this.adminInfo['avatar'] = values['avatar'];
                    this.adminInfo['name'] = values['name'];
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            })
        },

        //更新系统信息
        UpdateSystemInfo(values){
            return new Promise((resolve, reject) => {
                updateSystemInfo(values).then(response => {
                    this.GetAdminInfo();
                    resolve(response);
                }).catch(error => {
                    reject(error);
                })
            })
        },

        //退出登录
        Logout(){
            return new Promise((resolve) => {
                logout().then((res) => {
                    storage.remove('token');

                    asyncRouterStore.addRouters = [];
                    resolve();
                })
            })
        }
    }
})

export default useAdmin;