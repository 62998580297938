import { createApp } from 'vue'
import Antd from 'ant-design-vue';
import App from './App.vue'
import 'ant-design-vue/dist/antd.less';

import router from './router';

import pinia from "./stores/store";

import { direct } from './core/directives/action'

import { auth } from './core/permission/permission'

import './permission'


const app = createApp(App);

import common from './utils/common.js';
app.config.globalProperties.$common = common;

import moment from "moment"; 
app.config.globalProperties.$moment = moment;

direct(app);

app.config.globalProperties.$auth = auth;

app.use(Antd).use(router).use(pinia).mount('#app')
