
import UserLayout from '@/layouts/UserLayout';

//基础路由
export const constantRouterMap = [
    {
        path: '/admin', 
        redirect: '/admin/login',
        component: UserLayout,
        meta:{
            title:'登录'
        },
        children: [
            {
                path: 'login',
                name:'login',
                component: () => import('@/pages/admin/Login')
            },
        ]
    },
    {
        path: '/404',
        component: () => import( '@/pages/404')
    }
];