import axios from 'axios'
import storage from 'store'
import { message } from 'ant-design-vue';

import useAdmin from '@/stores/admin';

const isDevelopment = process.env.NODE_ENV !== 'production';

// 创建 axios 实例
const request = axios.create({
    // API 请求的默认前缀
    baseURL: isDevelopment ? '/api' : 'https://api.maoxiubiji.com',
    //timeout: 10000 // 请求超时时间
});

// 异常拦截处理器
const errorHandler = (error) => {
    if (error.response) {
        const data = error.response.data;

        const token = storage.get('token');

        const adminStore = useAdmin();

        if(error.response.status === 401){
            message.error(data.message);
            if (token) {
                adminStore.Logout().then(() => {
                    setTimeout(() => {
                        window.location.reload()
                    }, 1500)
                });
            }
        }else{
            storage.remove('token');
            window.location.reload()
        }
    }

    return Promise.reject(error)
}

request.interceptors.request.use(config => {
    const token = storage.get('token');

    if (token) {
        config.headers['Authorization'] = 'Bearer ' + token;
    }

    return config
}, errorHandler);

request.interceptors.response.use((response) => {
    
    const token = response.headers && response.headers.authorization ? response.headers.authorization : '';
    if(token){
        storage.set('token', token);
    }

    return response.data
}, errorHandler)

export default request;