import request from '@/utils/request'

const systemApi = {
    UpdateSystemInfo: '/adminApi/system/updateSystemInfo',
}

//更新系统信息
export function updateSystemInfo(parameter){
    return request({
        url: systemApi.UpdateSystemInfo,
        method: 'post',
        data: parameter
    })
}
