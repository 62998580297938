import router from './router';
import storage from 'store';

import pinia from '@/stores/store'
import useAdmin from '@/stores/admin';
const adminStore = useAdmin(pinia);

import useAsyncRouter from '@/stores/async-router';
const asyncRouterStore = useAsyncRouter(pinia);

// 进度条
import NProgress from 'nprogress'
import '@/components/NProgress/nprogress.less'
NProgress.configure({ showSpinner: false })

//设置标题
import { setDocumentTitle, domTitle } from '@/utils/domUtil'

//免登录
const allowList = ['login']

//登录路由
const loginRoutePath = '/admin/login'
//默认路由
const defaultRoutePath = '/organization'

router.beforeEach((to, from, next) => {
    // 开启进度条
    NProgress.start()

    // 设置标题
    setDocumentTitle(`${domTitle}`)
    // to.meta && typeof to.meta.title !== 'undefined' && setDocumentTitle(`${domTitle} - ${to.meta.title}`)

   //storage.clearAll();

    if (storage.get('token')) {
        if (to.path === loginRoutePath) {
            next({ path: defaultRoutePath });

            NProgress.done() // 关闭进度条
        } else {
            if (asyncRouterStore.addRouters.length == 0) {
                adminStore.GetAdminInfo().then((res) => {
                    asyncRouterStore.GenerateRoutes().then((res) => {

                        //动态创建路由
                        asyncRouterStore.addRouters.forEach(r => {
                            router.addRoute(r);
                        });

                        const redirect = decodeURIComponent(from.query.redirect || to.path)
                        if (to.path === redirect) {
                            // set the replace: true so the navigation will not leave a history record
                            next({ ...to, replace: true })
                        } else {
                            // 跳转到目的路由
                            next({ path: redirect })
                        }
                    })
                })
            } else {
                next()
           }
        }
    } else {
        if (allowList.includes(to.name)) {
            // 在免登录名单，直接进入
            next()
        } else {
            next({ path: loginRoutePath, query: { redirect: to.fullPath } });
            NProgress.done() // 关闭进度条
        }
    }
});

router.afterEach(() => {
    NProgress.done() // 关闭进度条
})