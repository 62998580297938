import useAgent from '@/stores/admin';
const agentStore = useAgent();

export const direct = app => {
    // 自定义一个全局权限指令 `v-action`
    app.directive('action', (el, binding) => {
        // value 获取用户使用自定义指令绑定的内容
        const { value } = binding
        // 获取用户所有的权限按钮
        const permissionBtn = [];
        const roles = agentStore.permissions;
        roles.forEach(item => {
            permissionBtn.push(item.permission)
        })

        // 判断用户使用自定义指令，是否使用正确了
        if (value && value instanceof Array && value.length > 0) {
            const permissionFunc = value
            //判断传递进来的按钮权限，用户是否拥有
            //Array.some(), 数组中有一个结果是true返回true，剩下的元素不会再检测
            const hasPermission = permissionBtn.some((role) => {
                return permissionFunc.includes(role)
            })

            // 当用户没有这个按钮权限时，设置移除这个按钮
            if (!hasPermission) {
                el.parentNode && el.parentNode.removeChild(el);
            }
        }else{
            console.log('权限指令错误');
        }
    })
};