<template>
    <div>
        <router-view />

        <div class="desc">
            <p class="des">
                <span class="line-left"></span>
                猫嗅笔记
                <span class="dot"></span>一款好用的企业推品工具<span class="line-right"></span>
            </p>
            <p class="records">Copyright © 2016-{{$moment().format('YYYY')}} 义乌市猫嗅网络科技有限公司 All Rights Reserved 
                <a style="color: #999;margin-left: 8px;" href="https://beian.miit.gov.cn/" target="_blank">浙ICP备16010149号-8</a> 
                <a style="display: flex;color: #999;margin-left: 8px;" href="http://www.beian.gov.cn/portal/registerSystemInfo?recordcode=33078202002117" target="_blank"><img style="width: 20px;height: 20px;" src="../assets/images/beianIcon.png" />浙公网安备 33078202002117号</a>
            </p>
        </div>
    </div>
</template>
  
<script>
export default {
    name: 'UserLayout',
}
</script>
  
<style lang="less" scoped>
.desc {
    line-height: 20px;
    text-align: center;
    position: absolute;
    bottom: 1.2%;
    left: 50%;
    transform: translateX(-50%);
    margin-bottom: 0;
    min-width: 600px;

    .des {
        position: relative;
        margin: 0;
        font-size: 14px;
        letter-spacing: -0.2px;
        color: #999;
        font-weight: 400;
        font-family: 'PingFang SC';
        text-align: center;

        .line-left {
            position: relative;
            display: inline-block;
            border-bottom: 1px solid #999;
            top: -3px;
            width: 40px;
            border-radius: 0.5px;
            margin-right: 16px;
        }

        .dot {
            display: inline-block;
            width: 3px;
            height: 3px;
            margin: 3px 5px;
            border-radius: 50%;
            background-color: #888888;
        }

        .line-right {
            position: relative;
            display: inline-block;
            border-bottom: 1px solid #999;
            top: -3px;
            width: 40px;
            -webkit-border-radius: 0.5px;
            border-radius: 0.5px;
            margin-left: 16px;
        }
    }

    .records {
        display: flex;
        align-items: center;
        margin: 8px 0 0;
        font-size: 12px;
        color: #999;
        white-space: nowrap;
    }
}</style>
  