import useAgent from '@/stores/admin';
const agentStore = useAgent();

export const auth = (values) => {
    // 获取用户所有的权限按钮
    const permissionBtn = [];
    const roles = agentStore.permissions;
    roles.forEach(item => {
        permissionBtn.push(item.permission)
    })

    const permissionFunc = values;
    //判断传递进来的按钮权限，用户是否拥有
    //Array.some(), 数组中有一个结果是true返回true，剩下的元素不会再检测
    const hasPermission = permissionBtn.some((role) => {
        return permissionFunc.includes(role)
    })

    return hasPermission;
}