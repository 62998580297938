
function cdnurl(url, domain) {
    var rule = new RegExp('^((?:[a-z]+:)?\\/\\/|data:image\\/)', 'i')
    var url = rule.test(url) ? url : 'https://oss.maoxiubiji.com/' + url
    if (domain && !rule.test(url)) {
        domain = typeof domain === 'string' ? domain : location.origin
        url = domain + url
    }
    return url
}
 
export default {    
    cdnurl,
}